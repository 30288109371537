@font-face {
  font-family: 'avenirNextRegular';
  src: url('/public/assets/fonts/AvenirNextLTPro-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'avenirNextMedium';
  src: url('/public/assets/fonts/AvenirNextLTPro-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'avenirNextBold';
  src: url('/public/assets/fonts/AvenirNextLTPro-Bold.otf') format('truetype');
}
.container {
  max-width: 1440px;
}
bode {
  font-family: AvenirNext-Regular, Roboto, Helvetica Neue, sans-serif;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
body {
  font-family: 'avenirNextRegular', sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  transition: all 0.3s ease;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background: #2daf4a;
  border: 0 #fff;
  border-radius: 38px;
}
::-webkit-scrollbar-track {
  background: #ccc;
  border: 0 #fff;
  border-radius: 50px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.theme-color {
  color: #2daf4a;
}
.dark-bg {
  background: #222;
}
.dark-border {
  border: 1px solid #222;
}
.f-medium {
  font-family: 'avenirNextMedium';
}
.bold {
  font-family: 'avenirNextBold';
}
.upper-caps {
  text-transform: uppercase;
}
.h1 {
  font-size: 2.5em;
}
h1 {
  font-family: 'avenirNextMedium', sans-serif;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #141414;
  font-weight: 500;
  letter-spacing: -1px;
}
p {
  font-weight: 600;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: initial;
  height: 0;
  overflow: visible;
}
/* fixed Nicotine Warning MSG */
.warning-msg__wraper {
  background: #fff;
  border: 10px solid #070707;
  height: 200px;
}

.msg-heading {
  font-family: 'avenirNextBold';
  font-size: 3rem;
  line-height: 250%;
  margin-right: 1.25rem;
}
.msg-heading span svg {
  margin-top: -10px;
}
.msg-text {
  font-family: 'avenirNextBold';
  font-size: 3.2em;
  padding: 29px 1rem;
  text-align: center;
  line-height: 1.2;
}
/* End warning MSG */
/* Main Navigation */
.navbar {
  background: #fff;
  height: 70px;
  border-bottom: 20px solid #2daf4a;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 0.3s ease;
}
.navbar-brand {
  padding: 4px 0 0;
  max-height: 60px;
  width: 150px;
}
.navbar-brand:hover,
.navbar-brand:focus {
  background: none;
}
.navbar-brand img {
  width: 100%;
}
.navbar-nav {
  /* margin-top: 20px; */
}
.navbar-light .navbar-nav .nav-link {
  color: #2daf4a !important;
  text-transform: uppercase;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  padding: 15px;
  margin-top: 20px;
  border-bottom: 20px solid transparent;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  background: #2daf4a;
  color: #000 !important;
  border-bottom: 20px solid #000;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #2daf4a;
  border-bottom: 10px solid #2daf4a;
}
.nav-link-custom .nav-item-custom {
  color: #2daf4a;
}

/* clone menu */
nav {
  border-bottom: 20px solid #2daf4a;
  background: #fff;
  transition: all 0.3s ease;
  height: 70px;
  position: relative;
}
nav .header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .header-inner .logo img {
  max-height: 120px;
  padding: 0;
  position: absolute;
  top: -10px;
  z-index: 11;
}
nav .header-inner .menu-desktop {
  display: none;
  position: relative;
}
nav .header-inner .menu-desktop .user-profile {
  line-height: 1.2;
  background: #fff;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 2;
  min-width: 200px;
  color: #2daf4a;
}
nav .header-inner .menu-desktop ul {
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 0;
  padding: 0 15px;
}
nav .header-inner .menu-desktop .user-profile img {
  position: absolute;
  left: 0;
  top: 5px;
  height: 90px;
  z-index: 11;
}
nav .header-inner .menu-desktop .user-profile .pts p {
  color: #2daf4a;
  font-weight: 700;
  margin: 0 0 0 70px;
  letter-spacing: -0.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
nav .header-inner .menu-desktop ul li {
  border-bottom: 1px solid #e9e9e9;
  transition: all 0.3s ease;
}
nav .header-inner .menu-desktop ul li a {
  padding: 0;
  letter-spacing: -0.25px;
  font-size: 1.25em;
  color: #fff;
  font-family: 'avenirNextRegular';
  line-height: 50px;
  font-weight: 700;
  transition: all 0.3s ease;
  border-bottom: none;
  cursor: pointer;
}
nav .header-inner .menu-desktop ul li a:hover {
  color: #fff;
  text-decoration: none;
  border-bottom: 10px solid #141414;
}
nav .header-inner .menu-desktop .notification .mat-icon {
  font-size: 35px;
  height: 35px;
  width: 35px;
}
.material-icons {
  transition: transform 0.5s;
}
nav .header-inner .menu-desktop .notification {
  display: none;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  color: #2daf4a;
}
nav .header-inner .menu-desktop .notification .notification-center {
  position: absolute;
  right: 0;
  top: 85px;
  background: #fff;
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.3s ease;
  padding: 10px;
  border-radius: 5px;
  visibility: hidden;
  border: 1px solid #2daf4a;
  min-width: 310px;
  max-width: 410px;
}
nav .header-inner .menu-desktop .notification .notification-center:before {
  content: '';
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -7px;
  right: 20px;
  transform: rotate(45deg);
  border-top: 1px solid #2daf4a;
  border-left: 1px solid #2daf4a;
  z-index: 0;
}
nav .header-inner .menu-desktop .notification .notification-center .notifications {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
  padding-right: 10px;
}
nav .header-inner .menu-desktop .notification .notification-center .list-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease;
  margin-bottom: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
}
nav .header-inner .menu-desktop .notification .notification-center .list-notification .info p {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  line-height: 1.25;
}
nav .header-inner .menu-desktop .notification .notification-center .list-notification .icon {
  color: #2daf4a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
nav .header-inner .menu-desktop .notification .notification-center .list-notification .icon .black {
  color: #000;
}
nav .header-inner .menu-desktop .notification .notification-center .load-more {
  display: flex;
  justify-content: center;
  align-items: center;
}
nav .header-inner .menu-desktop .notification .notification-center .notify-header p {
  padding: 12px 10px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 0.5px solid rgba(140, 117, 48, 0.4);
  letter-spacing: -0.25px;
  font-family: 'avenirNextMedium';
  margin: 0 0 10px;
}
nav .header-inner .menu-desktop .notification .notification-center .mat-icon {
  font-size: 20px;
  height: 20px;
  width: 20px;
}
nav .header-inner .menu-mobile-icons {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
}
nav .header-inner .menu-mobile-icons .mat-icon {
  height: 32px;
  width: 32px;
  font-size: 32px;
  cursor: pointer;
  z-index: 10;
  margin: 0 2.5px;
  color: #2daf4a;
}
.mobile-drawer .drawer-inner {
  width: 100%;
  max-width: 310px;
  background: #fff;
  border-right: 10px solid #8c7530;
  z-index: 12;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-330px);
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.mobile-drawer.open .drawer-inner {
  transform: translateX(0);
}
.mobile-drawer .drawer-inner .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-drawer .drawer-inner .title h3 {
  margin: 30px 25px;
  font-weight: 700;
  font-size: 1.75rem;
}
.mobile-drawer .drawer-inner .title .mat-icon {
  height: 30px;
  width: 30px;
  color: #2daf4a;
  margin-right: 10px;
  cursor: pointer;
}
.mobile-drawer .drawer-inner ul.mobile-nav {
  list-style: none;
  padding: 0 20px 30px;
  border-bottom: 1px solid #e9e9e9;
}
.mobile-drawer .drawer-inner ul.mobile-nav li {
  display: flex;
  padding: 7px 5px;
  justify-content: flex-start;
  align-items: center;
  margin: 7px 0;
  background: rgba(140, 117, 49, 0.011764705882352941);
  border-radius: 10px;
}
.mobile-drawer .drawer-inner ul.mobile-nav li .mat-icon {
  color: #2daf4a;
  margin-right: 20px;
  height: 30px;
  width: 30px;
  font-size: 30px;
}
.mobile-drawer .drawer-inner ul.mobile-nav li a {
  text-decoration: none;
  font-family: 'avenirNextMedium';
  color: #4c4c4c;
  font-weight: 700;
  font-size: 1.1em;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.account-menu,
.notification-center-mob {
  position: fixed;
  left: 0;
  width: 100%;
  background: #fff;
  transition: all 0.3s ease;
  padding: 10px;
  z-index: 10;
  transform: translateX(110%);
  visibility: hidden;
  opacity: 0;
  top: 135px;
  height: calc(100% - 135px);
  color: #fff;
}
.account-menu .notify-header,
.notification-center-mob .notify-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  text-align: center;
}
.account-menu .notify-header p,
.notification-center-mob .notify-header p {
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.25px;
  font-family: 'avenirNextMedium';
  margin: 0;
  color: #000;
  text-transform: uppercase;
}
.account-menu .notify-header .mat-icon,
.notification-center-mob .notify-header .mat-icon {
  color: #141414;
  position: absolute;
  right: 10px;
}
.account-menu .notifications,
.notification-center-mob .notifications {
  overflow-y: auto;
  margin: 20px 0;
  height: calc(100% - 150px);
  padding-bottom: 80px;
}
.account-menu .list-notification,
.notification-center-mob .list-notification {
  padding: 10px;
  margin: 0 10px 10px 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.account-menu .list-notification .notification,
.notification-center-mob .list-notification .notification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.account-menu .list-notification .icon,
.notification-center-mob .list-notification .icon {
  color: #2daf4a;
  background: #fff;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.account-menu .list-notification .info p,
.notification-center-mob .list-notification .info p {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: #2daf4a;
}
.account-menu .list-notification .notification-cta,
.notification-center-mob .list-notification .notification-cta {
  margin: 0;
}
.account-menu .list-notification .icon .notification-center-mob .list-notification .icon {
  color: #2daf4a;
  background: #fff;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.account-menu .load-more,
.notification-center-mob .load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.notification-center-mob.show,
.show.account-menu {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.account-menu .list-notification .info p.black,
.notification-center-mob .list-notification .info p.black,
.account-menu .list-notification .icon .black,
.notification-center-mob .list-notification .icon .black {
  color: #000;
}
.account-menu:before,
.notification-center-mob:before {
  content: '';
  width: 13px;
  height: 13px;
  background: #2daf4a;
  position: absolute;
  top: -7px;
  transform: rotate(45deg);
  border-top: 1px solid #f2f4f9;
  border-left: 1px solid #f2f4f9;
  z-index: 0;
}
.account-menu:before {
  right: 72px;
}
.notification-center-mob:before {
  right: 34px;
}
.account-menu .load-more:before,
.notification-center-mob .load-more:before {
  content: '';
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.611764705882353), transparent);
  position: absolute;
  height: 70px;
  top: -90px;
}

.account-menu .user-profile {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.account-menu .account-overview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.account-menu .account-overview img {
  width: 80px;
}
.account-menu .account-overview .pts {
  text-align: center;
  color: #141414;
}
.account-menu .account-overview .pts p {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
}
.account-menu .account-desktop-menu ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.account-menu .account-desktop-menu ul li {
  display: flex;
  flex-basis: calc(50% - 10px);
  align-items: center;
  margin: 5px;
  background: #141414;
  border-radius: 5px;
}
.account-menu .account-desktop-menu ul li a {
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  padding: 10px 7px;
  display: flex;
  align-items: center;
  width: 100%;
}
.account-menu .account-desktop-menu ul li a span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-menu .account-desktop-menu ul li a .mat-icon {
  font-size: 22px;
  height: 22px;
  width: 22px;
  margin-right: 5px;
}

.account-menu .account-desktop-menu ul li a:focus,
.account-menu .account-desktop-menu ul li a:hover {
  color: #fff;
  background: #141414;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  visibility: hidden;
}
.mobile-drawer.open .overlay {
  visibility: visible;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
}

@media screen and (min-width: 1150px) {
  nav .header-inner .logo img {
    margin-left: 10px;
    max-height: 150px;
  }
  nav {
    height: 80px;
  }
  nav .header-inner .menu-desktop {
    display: flex;
  }
  nav .header-inner .menu-desktop .user-profile:hover .account-desktop-menu {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }
  nav .header-inner .menu-desktop .user-profile .account-desktop-menu {
    width: 100%;
    position: absolute;
    right: 0;
    top: 85px;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    border: 1px solid #2daf4a;
  }
  nav .header-inner .menu-desktop .user-profile .account-desktop-menu:before {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 26px;
    transform: rotate(45deg);
    border-top: 1px solid #2daf4a;
    border-left: 1px solid #2daf4a;
    z-index: 0;
  }
  nav .header-inner .menu-desktop .user-profile .account-desktop-menu ul {
    flex-direction: column;
    padding: 0;
  }
  nav .header-inner .menu-desktop ul {
    list-style: none;
    display: flex;
    flex-flow: row;
    margin-bottom: 0;
  }
  nav .header-inner .menu-desktop .user-profile .account-desktop-menu ul li a {
    font-weight: 700;
    font-size: 1em;
    color: #2daf4a;
    padding: 5px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 5px;
    border: 0;
    line-height: normal;
    text-transform: inherit;
  }
  nav .header-inner .menu-desktop .user-profile .account-desktop-menu ul li .mat-icon {
    margin-right: 5px;
    color: #2daf4a;
    transition: all 0.3s ease;
  }

  nav .header-inner .menu-desktop .user-profile .account-desktop-menu ul li a:hover {
    background: #2daf4a;
    transform: translateX(2%);
    color: #fff;
  }

  nav .header-inner .menu-desktop .user-profile .account-desktop-menu ul li a:hover .mat-icon {
    color: #fff;
  }
  nav .header-inner .menu-desktop ul li {
    border-bottom: none;
  }
  nav .header-inner .menu-desktop ul li a {
    padding: 20px 15px;
    letter-spacing: 0;
    font-size: 1.05em;
    color: #2daf4a;
    font-family: 'avenirNextRegular';
    line-height: 60px;
    font-weight: 700;
    transition: all 0.3s ease;
    border-bottom: 20px solid transparent;
    text-transform: uppercase;
    text-decoration: none;
  }
  nav .header-inner .menu-desktop ul li a:hover {
    color: #000;
    text-decoration: none;
    border-bottom: 20px solid #141414;
  }

  nav .header-inner .menu-desktop .notification {
    display: flex;
  }
  nav .header-inner .menu-desktop .notification:hover .notification-center {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }
  nav .header-inner .menu-mobile-icons {
    display: none;
  }
  .mobile-drawer,
  .overlay {
    visibility: hidden;
  }
  .account-menu,
  .notification-center-mob {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .account-menu,
  .notification-center-mob {
    top: 218px;
    height: calc(100% - 218px);
  }
}
@media screen and (min-width: 350px) {
  .account-menu,
  .notification-center-mob {
    top: 140px;
    height: calc(100% - 140px);
  }
  .account-menu .account-desktop-menu ul li {
    font-size: 1em;
  }
}

/* end clone menu */

/* End Main Navigation */
/* Banner */
.home-banner-img {
  background: url(/public/assets/images/leapvapor-banner.jpg) center center;
  min-height: 600px;
}
.carousel-caption {
  /* background: rgba(255, 255, 255, 0.9);
    outline: 10px solid rgba(45, 175, 73, 0.4); 
    border-radius: 30px; */
  justify-content: center;
  left: 0;
  right: auto;
  width: 100%;
  top: 90px;
  bottom: auto;
  color: #2daf4a;
  padding: 50px 20px 30px 0;
  z-index: 9;
}
.carousel-caption h1 {
  font-family: 'avenirNextBold';
  font-size: 4em;
  line-height: 1.1em;
  margin-bottom: 20px;
  color: #fff;
}
.carousel-caption h1 span {
  color: #2daf4a;
}

.carousel-caption p {
  text-align: left;
  font-weight: 500;
}
.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
  display: none;
}

.carousel-caption .btn-primary {
  background-color: #2daf4a;
  border: #2daf4a;
  border-radius: 4px;
  font-family: 'avenirNextMedium';
  font-size: 24px;
  padding: 15px 1rem 15px;
  font-weight: 700;
  outline: none !important;
  margin: 5px;
  letter-spacing: -0.2px;
}

.carousel-caption .btn-primary:hover,
.carousel-caption .btn-primary:focus {
  background: #2daf4a !important;
  color: #fff;
  outline: none !important;
}

/* End Banner */

/* Pages wise */

.profile-img img {
  width: 100%;
  min-height: 300px;
  max-height: 350px;
  object-fit: cover;
}
.page-heading {
  position: relative;
  z-index: -20;
}
.page-heading .overlay-mask {
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
}
.page-heading .overlay-mask p {
  color: #fff;
}
.page-heading h1 {
  font-size: 2.5em;
  color: #fff;
  font-weight: bold;
}

.register-page {
  margin-bottom: 0 !important;
}

.register-page .card {
  border-radius: 30px 30px 0 0;
  border: none;
  max-width: 750px;
  padding: 70px 50px 120px;
  box-shadow: 0px -5px 20px rgb(25 59 104 / 15%);
  margin-bottom: 0;
  background: linear-gradient(180deg, white 70%, transparent);
}

/* home */
.leap-white-line-logo {
  height: 56px;
  text-align: center;
}
.age-question {
  font-size: 1.75rem;
  font-weight: 700;
}
.modal-note {
  font-size: 13.5px;
}
.modal-note p {
  font-weight: normal;
}
/* after login */
.home-icon-step {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
  -webkit-box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
  -moz-box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
  margin: 20px auto 30px;
}
.home-icon-step img {
  padding: 15px;
}
.bg-green {
  background-color: #2daf4a;
  margin-top: 150px;
}
.home-faq h1 {
  color: #fff;
  font-family: 'avenirNextBold';
  font-size: 2.5em;
  text-align: left;
  line-height: 1.2em;
}
.home-faq .btn-link {
  text-decoration: none;
  width: 100%;
}
.home-faq .btn-link h5 {
  text-transform: uppercase;
  font-family: 'avenirNextBold';
  color: #000;
  font-size: 15px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.home-faq .card {
  margin-bottom: 0;
}
.home-faq .card-header {
  padding: 8px 10px;
  border-bottom: none;
  background-color: #fff;
}
.home-faq .card-body {
  font-size: 14px;
  padding-top: 5px;
}
.faqimg {
  margin-top: -100px;
  width: 100%;
}
.card-header .collapsed .show-more,
.card-header .show-less {
  color: #000;
}
.card-header .collapsed .show-more {
  display: inline-block;
}
.card-header .show-more {
  display: none;
}
.card-header .collapsed .show-less {
  display: none;
}
.card-header .show-less {
  display: inline-block;
}

.btn,
button {
  outline: none !important;
  border: none;
  box-shadow: none;
}
button:focus,
button:active,
.btn:focus,
.btn:active {
  outline: 0;
  border: none;
  box-shadow: none;
}
.btn-page-change {
  background-color: #2daf4a;
  color: #fff;
  width: 40px;
  height: 40px;
  margin: 0 4px;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  padding: 0;
}
.btn-page-change span {
  text-align: center;
  font-size: 30px;
  line-height: 40px;
  margin: 0 auto;
}
.btn-page-change:hover {
  background-color: #2daf4a;
  color: #fff;
}
.btn-page-change:disabled {
  color: rgba(0, 0, 0, 0.26);
  opacity: 1;
}
.page-no-label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.join-community {
  overflow-x: hidden;
}
.join-community img {
  width: 100%;
  object-fit: cover;
  min-height: 250px;
}
.join-community .overlay-mask {
  background-color: rgba(0, 0, 0, 0.1);
}
.join-community .btn-primary-inverse {
  background-color: #fff;
  color: #2daf4a;
  font-size: 1.2em;
  width: 300px;
  margin-top: 15px;
  font-weight: 700;
  padding: 10px;
}
.join-community .btn-primary-inverse:hover {
  background: #fff;
  color: #2daf4a;
}
.eranpoint-home .post-login {
  margin-top: 15px;
}
.eranpoint-home h2 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 30px;
}
.eranpoint-home h2 strong {
  font-weight: 900;
  font-family: 'avenirNextMedium';
}
/* login page */
.login-bg {
  background: url(/public/assets/images/login-bg.jpg) center top;
  padding-top: 40px;
}

/* registration survey */
.intro-welcome {
  text-align: center;
  letter-spacing: -1px;
  font-family: 'AvenirNext-Bold', sans-serif;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/public/assets/images/material-design-background.jpg) center center;
}
.survey-heading {
  border-bottom: 10px solid #2daf4a;
  font-size: 2.5rem;
  text-transform: uppercase !important;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.welcomepage .card {
  background: #f5f5f5;
  margin: -50px auto 10px auto;
  min-height: 500px;
  max-width: 1200px;
  border: 10px solid #2daf4a;
  padding: 50px 40px;
  border-radius: 10px;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}
.welcomepage .sub-text {
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
  font-family: 'avenirNextMedium';
}

/* submit code */
.submit-page {
  background: url(/public/assets/images/white-bg.jpg) 50%;
}
.submit-page h1,
.submit-page h2 {
  text-transform: inherit;
}
.smoke-bg {
  background: #786325 url(/public/assets/images/smoke-over.jpg) 0;
  background-blend-mode: hard-light;
  height: 150px;
}
.submit-content-bg {
  margin-top: 20px;
  min-height: 500px;
  margin-bottom: 100px;
  color: #fff;
}
.addproductcode .labelfieldmove label,
.addproductcode .labelfieldmove input {
  opacity: 1;
}
.addproductcode .labelfieldmove label {
  left: 15px;
  color: #000;
}
.addproductcode .form-group {
  position: relative;
}
.addproductcode .slect-labelfieldmove select {
  margin: 0;
  left: 15px;
}
.addproductcode .slect-labelfieldmove option {
  height: 30px !important;
}
.addproductcode .slect-labelfieldmove label {
  background: none !important;
  font-size: 16px;
  top: 26px !important;
  background-color: transparent;
  color: #000;
}
.css-yk16xz-control,
.css-yk16xz-control:hover,
.css-yk16xz-control:focus {
  border: none !important;
  background: none !important;
}
.addproductcode .form-control-select {
  padding: 0 !important;
}
.css-1pahdxg-control:focus,
.css-1pahdxg-control:hover,
.css-2b097c-container:focus,
.css-2b097c-container:hover,
.addproductcode .css-yk16xz-control:focus,
.addproductcode .css-yk16xz-control:hover {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-tlfecz-indicatorContainer {
  color: #070707 !important;
}
.css-g1d714-ValueContainer {
  padding: 25px 0 5px 15px !important;
}
.css-1uccc91-singleValue {
  top: 62% !important;
}
.css-26l3qy-menu {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  /* margin-top: -60px !important; */
  box-sizing: border-box;
  text-align: left !important;
}
.addproductcode .css-1wa3eu0-placeholder {
  font-size: 16px;
  color: #000;
}
.addproductcode .css-yk16xz-control:focus .css-1wa3eu0-placeholder {
  top: 5px;
}
.addproductcode .slect-labelfieldmove:focus ~ label,
.addproductcode .slect-labelfieldmove:not([value='']):valid ~ label {
  opacity: 1;
  transform: scale(1) translateY(-14px) translateX(0px);
  color: #2daf4a;
  font-size: 13px;
}
a.link {
  color: #fff;
  text-decoration: underline;
}
a.link:hover {
  color: #fff;
  background: none;
}

/* end submit code */
/* Earn Points */
.flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  filter: fliph;
  /*IE*/
}
.earnpoint-h-bg {
}
.activity-block:hover {
  background-color: #fff;
}
.activity-box .activity-block .cart-button {
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 0.6);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 0.6);
  -moz-box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 0.6);
  border-radius: 4px;
  border: 2px solid #0f7c24;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin: 10px 0px 0;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 6px;
}
.activity-box .card:hover .cart-button {
  color: #2daf4a;
}
.activity-box .card:hover .cart-button:hover {
  color: #fff;
}
.activity-block .activity-info h5 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #212529;
  font-family: 'avenirNextMedium';
}
.activity-block .activity-info p {
  font-size: 1rem;
}
.activity-box .activity-block,
.activity-box .activity-block .card-body {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}
/* End Earn Points */

/*  Support page*/

/* End Support page */
/*  My rewards page*/
.timeline-wraper {
  padding-bottom: 60px;
}
.timeline-content {
}
.history-timeline {
  border-left: 4px solid #2daf4a;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0 auto;
  position: relative;
  line-height: 1.4em;
  font-size: 1em;
  padding: 50px 0 50px 50px;
  list-style: none;
  text-align: left;
  max-width: 80%;
}
.history-timeline .cell {
  border-bottom: 1px dashed fade(#fff, 10%);
  margin-bottom: 50px;
  position: relative;
  background: #f5f5f5;
  padding: 25px;
  border-radius: 25px;
}
.history-timeline .cell .points-show {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: -92px;
  background: #141414;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  color: #fff;
  top: 0;
  position: absolute;
}

.history-timeline .cell h4 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #141414;
}
.history-timeline .cell .btn-link {
  text-decoration: underline;
  font-size: 24px;
  font-weight: 600;
  color: #141414;
  padding: 0;
}
.history-timeline .cell .btn-link:hover,
.history-timeline .cell .btn-link:focus {
  background: none;
  text-decoration: underline;
}
.history-timeline .cell .content {
  line-height: 22px;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  /* max-height: 80vh; */
}
.history-timeline .cell .content a {
  color: #2daf4a;
  background: none;
}
.history-timeline .cell .content a:hover {
  text-decoration: underline;
}

.reward-details .MuiToolbar-root {
  display: block;
  padding: 0;
}
.reward-details h6 {
  background-color: #2daf4a;
  font-weight: 700;
  color: #fff;
  padding: 8px 15px;
  display: block;
  font-size: 18px;
}

.reward-details h4 {
  font-size: 24px;
  text-align: left;
  font-family: 'avenirNextMedium';
}

/* End My rewards page */

/* activity details pages */
.link-counter {
  border: 2px solid #2daf4a;
  border-right: none;
  border-left: none;
  padding: 10px;
}
.link-counter .center-item {
  display: flex;
  align-items: center;
}
.link-counter .text-center {
  flex-direction: column;
  justify-content: center;
}
.link-counter .text-right {
  justify-content: flex-end;
}
.btn-prev,
.btn-next {
  width: 220px;
  transition: padding 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
}
.btn-prev:hover {
  padding-right: 24px;
  padding-left: 8px;
}
.btn-next:hover {
  padding-right: 8px;
  padding-left: 24px;
}
.like-now {
  background: #fff;
  color: #2daf4a;
  padding: 7px 30px;
  border-radius: 25px;
  display: inline-block;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
}
.like-now:hover {
  border: 1px solid #2daf4a;
  cursor: pointer;
}
.like-now button {
  color: #2daf4a;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}
.like-now button:hover {
  color: #2daf4a;
  background-color: #f5f5f5;
}
.item-icon {
  vertical-align: middle;
  display: inline-flex;
}
.button-ripple,
.button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}
.ripple {
  overflow: hidden;
  position: relative;
}
.watch-video .support-form-wrapper {
  background: hsla(0, 0%, 96.9%, 0.6);
  border-radius: 25px;
  padding: 30px;
  margin: 20px auto;
}
.watch-video .support-form-wrapper .support-form {
  padding: 60px 0;
}
.support-form-wrapper .support-form p {
  font-weight: 400;
}

.counter-liked p {
  color: #2daf4a;
  font-weight: 400;
  margin-bottom: 0;
}
/* End activity details pages */
/* End Pages wise */
/* Form Elements */
.divider {
  height: 1px;
  background-color: #fff;
  opacity: 0.2;
  margin: 10px auto;
}
.border-1 {
  border: 1px solid #fff;
}
.form-group {
  display: block;
}
.page-form {
  padding: 60px 0 200px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.col-form-label {
  font-weight: normal;
  font-size: 17px;
  word-break: break-all;
}
.form-group .form-control {
  background-color: #e9e9e9;
  color: #000;
  border-bottom: 1px solid #555555;
  border-radius: 4px 4px 0 0;
  font-size: 18px;
  font-weight: 600;
  padding: 0.75em 0.75em 0 15px;
  height: 60px;
  line-height: 1.125;
  transition: all 0.3s ease;
}
.form-group .form-control::placeholder {
  color: #fff;
  font-weight: 400;
  font-size: 1.125rem;
}
.form-group .form-control:focus {
  outline: none;
  transition: all 0.3s ease;
}

/* outline fields */
.outline-field .labelfieldmove {
  position: relative;
  margin: 5px 0px;
}

.outline-field .labelfieldmove label {
  position: absolute;
  padding: 2px 4px;
  top: 14px;
  left: 20px;
  cursor: text;
  transition: all 0.3s ease;
  font-weight: 700;
  font-size: 18px;
  background-color: #fff;
}

.outline-field .labelfieldmove input {
  border: 1px solid rgb(172, 172, 172);
  background-color: #fff;
  border-radius: 5px;
}
.outline-field .labelfieldmove input:focus + label,
.outline-field .labelfieldmove input:not(:placeholder-shown) + label {
  opacity: 1;
  transform: scale(1) translateY(-24px) translateX(0px);
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.outline-field .labelfieldmove input:hover {
  border: 2px solid #000;
  outline-color: #000;
  transition: all 0.3s ease;
}

.outline-field .labelfieldmove input:focus {
  border: 2px solid #000;
  transition: all 0.3s ease;
  box-shadow: none;
}

.outline-field .labelfieldmove.error input {
  border: 2px solid red !important;
  box-shadow: none;
}

.outline-field .labelfieldmove.error label {
  color: red;
}

.outline-field .labelfieldmove {
  padding: 0px 10px 0;
  margin: 0;
}

.outline-field .labelfieldmove input {
  width: 100%;
  padding-left: 15px;
}

.outline-field .labelfieldmove label,
.outline-field .labelfieldmove input {
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.75;
}
.outline-field .slect-labelfieldmove {
  position: relative;
}

.outline-field .slect-labelfieldmove select {
  border-radius: 5px;
  border: 1px solid lightgray;
  margin: 0;
  background-color: #fff;
}

.outline-field .slect-labelfieldmove label {
  position: absolute;
  top: -10px;
  left: 25px;
  background: #fff;
  font-size: 14px;
  opacity: 1;
}
/* end outline fields */

.slect-labelfieldmove {
  position: relative;
}
.slect-labelfieldmove select {
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 20px 60px 15px 10px;
  margin: 0 20px;
}
.slect-labelfieldmove label {
  position: absolute;
  top: -7px;
  left: 35px;
  background: #fff;
  font-size: 11px;
}

.labelfieldmove {
  position: relative;
  margin: 5px 0px;
}
.labelfieldmove label {
  position: absolute;
  padding-left: 15px;
  top: 20px;
  cursor: text;
  transition: all 0.3s ease;
  font-weight: 700;
}
.labelfieldmove input:focus + label,
.labelfieldmove input:not(:placeholder-shown) + label,
.labelfieldmove textarea:focus + label,
.labelfieldmove textarea:not(:placeholder-shown) + label {
  opacity: 1;
  transform: scale(1) translateY(-14px) translateX(0px);
  color: #2daf4a;
  font-size: 13px;
}
.labelfieldmove input:hover,
.labelfieldmove textarea:hover {
  border-bottom: 2px solid #000;
  outline-color: #000;
  transition: all 0.3s ease;
}
.labelfieldmove input:focus,
.labelfieldmove textarea:focus {
  border-bottom: 2px solid #2daf4a;
  transition: all 0.3s ease;
  box-shadow: none;
}
.labelfieldmove.error input {
  border-bottom: 2px solid red !important;
  box-shadow: none;
}
.labelfieldmove.error label {
  color: red;
}

.labelfieldmove {
  padding: 0px 15px 0;
  margin: 0;
}
.labelfieldmove input {
  width: 100%;
  padding-left: 15px;
}
.labelfieldmove textarea {
  padding-top: 25px !important;
}
.labelfieldmove label,
.labelfieldmove input,
.labelfieldmove textarea {
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.75;
}

.input-error {
  font-size: 12px;
  margin-top: 5px;
  padding-left: 10px;
  color: #f44336;
}

/* CTA and buttons/anchors */
.btn-primary {
  background-color: #2daf4a;
  border: #2daf4a;
  padding: 16px 1rem;
  font-size: 12px;
  font-weight: 600;
  border-radius: 0;
}
.btn-primary:hover,
.btn-primary:focus {
  background: #2daf4a !important;
  color: #fff;
}
.btn-submit {
  width: 220px;
  transition: padding 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
}
.btn-submit::after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 14px;
  right: 0;
  transition: 0.5s;
  color: #fff;
}
.btn-submit:hover {
  padding-right: 24px;
  padding-left: 8px;
}
.btn-submit:hover::after {
  opacity: 1;
  right: 70px;
}
a {
  color: #141414;
  font-weight: 600;
}
a:hover {
  background-color: #fff;
  color: #141414;
}
a:focus {
  background-color: #fff;
}
p a {
  color: #2daf4a;
}
p a:hover,
p a:focus {
  color: #2daf4a;
}

/* MODAL */

.modal-green-bg .modal-content {
  background-color: #2daf4a !important;
  border: none;
  border-radius: 4px;
  color: #fff !important;
}
.modal-green-bg .modal-body .ghost-text {
  border: 1px solid #fff;
  color: #fff;
  background: none;
  padding: 15px;
  text-align: center;
}
.modal-green-bg .modal-footer {
  border-top: 1px solid rgba(222, 226, 230, 0.4) !important;
}
.modal-green-bg .modal-content .btn {
  font-size: 16px;
  color: #000 !important;
  font-weight: 600;
}
.modal-green-bg .modal-content .btn-secondary {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
}
.modal-green-bg .modal-content .btn-primary {
  background: #fff !important;
  width: 210px;
  padding: 7px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.box-shadow {
  box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 0.6);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 0.6);
  -moz-box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 0.6);
}
/* END MODAL */

/* MODAL White */
.modal-bg-white .modal-content {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 1rem;
  font-weight: 500;
}

.modal-bg-white .modal-content .btn-primary {
  color: #fff !important;
  background-color: #2daf4a !important;
  font-size: 12px;
  padding: 15px 0;
}

/* END MODAL White */

/* End Form Elements */

/* Footer Section */
footer {
  border-top: 20px solid #2daf4a;
  background-color: #fff;
  padding-bottom: 0;
  padding-top: 0px;
  color: #000;
}
footer p {
  line-height: 1.5rem;
  font-weight: 400;
  font-size: 14.4px;
}

.footer {
  padding-top: 0px;
  padding-bottom: 2px;
}
footer a {
  color: #2daf4a;
  font-size: 1em;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.2px;
  transition: all 0.3s ease;
  line-height: 2.25;
}
footer a:hover {
  color: #2daf4a;
  text-decoration: none;
}

footer .footer_nav a {
  font-size: 1.2em;
}
footer .footer_nav .pipe-separate {
  margin-top: 3px;
}

.footer-social {
  margin-bottom: 0;
}

.footer-logo_wrap {
  justify-content: center;
  display: flex;
}

.footer-logo_wrap img {
  width: 250px;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.social-links {
  justify-content: left;
}

.footer .footer-link {
  /* justify-content: left; */
}
.pipe-separate {
  margin-top: 0px;
  display: block;
  color: #6a6a6a;
  font-size: 20px;
}
.footer .footer-link ul,
.footer-link ul {
  padding-left: 0;
  margin: 10px 0 0;
  list-style: none;
}

.footer-bot {
  /* background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.15); */
  padding-top: 8px;
  padding-bottom: 6px;
}

.footer-bot .footer-link {
  justify-content: right;
}

.footer-warning-icon svg {
  margin-top: -3px;
  margin-right: 4px;
}
footer .text-link {
  color: #000;
  font-weight: 600;
}
footer .text-link:hover strong {
  color: #000;
}
.copy-text {
  text-align: center;
  margin: 0;
}

.copy-text p {
  font-weight: 700;
  letter-spacing: -0.5px;
  font-size: 1em !important;
}

.tierlogic {
  line-height: 40px;
  font-size: 1em;
}

.tierlogic img {
  max-height: 26px;
}

.tier-information {
  width: 100%;
  transition: all 0.3s linear;
  border-top: 5px solid #2daf4a;
  bottom: -5px;
  color: #000;
  z-index: 9;
}
.current-level {
  padding-right: 6px;
}
.next-level {
  padding-left: 6px;
}
.current-level,
.next-level {
  max-width: 280px;
  height: 80px;
}
.current-level .level-name,
.next-level .level-name {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.current-level .level-name p,
.next-level .level-name p {
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
  width: 100%;
  text-transform: capitalize !important;
}
.current-level .level-name h5 {
  color: #2daf4a;
}
.next-level .level-name h5 {
  color: #212529;
}

.current-level .level-name h5,
.next-level .level-name h5 {
  font-size: 1.1em;
  text-transform: lowercase;
}

.current-level .level-name h5:first-letter,
.next-level .level-name h5:first-letter {
  text-transform: capitalize;
}

.current-percentage {
  float: right;
  width: 37%;
  padding-top: 5px;
  word-break: break-word;
}
.current-percentage .progress {
  height: 25px;
  border-radius: 13px;
}
.current-percentage .bg-success {
  background-color: #094b16 !important;
}
.toggle-switch {
  position: absolute;
  right: 10px;
  height: 30px;
  top: -30px;
  background: #2daf4a;
  padding: 2px 15px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #2daf4a;
  border-bottom: none;
  cursor: pointer;
}
/* End Footer Section */

/* for Device responsive */
@media (min-width: 991.98px) {
  .top-navigation {
    justify-content: end;
  }
}
@media (max-width: 1024px) {
  .msg-text {
    font-size: 2em;
    padding: 10px 1rem;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 320px) and (max-width: 359.98px) {
  .modal-dialog {
    margin: 0.5rem 0;
  }
  .modal-green-bg .modal-content .btn-primary {
    width: 110px;
    max-width: 120px;
  }
  .register-page .card {
    padding: 20px 30px;
  }
  .home-banner-img {
    background-position-x: 630px;
  }
  .home-faq .card-header {
    padding: 8px 0px;
  }
  .card-header .col-11 {
    padding-right: 5px;
    max-width: 89%;
  }
  .menu-mobile-icons .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: 9px;
  }
}
@media only screen and (min-width: 640px) and (max-width: 1024px) {
  .warning-msg__wraper {
    height: auto;
    padding: 10px 0;
  }
  .warning-msg .msg-heading,
  .warning-msg .msg-text {
    font-size: 1.6rem;
    line-height: normal;
  }
  /* .navbar {
    height: auto;
  }
  .navbar-brand {
    display: block;
    padding-top: 0;
    max-height: 60px;
  }
  .navbar-brand img {
    width: 120px;
    height: auto;
    margin-top: -4px;
  } */
  .carousel-caption {
    padding-left: 0;
    padding-right: 0;
  }
  .carousel-caption h1 {
    font-size: 2.5em;
  }
  .carousel-caption .btn-primary {
    font-size: 14px;
  }
  .menu-mobile-ic-ons .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: 9px;
  }
  .card-header .col-11 {
    max-width: 91%;
  }
}

@media (max-width: 639.99px) {
  .warning-msg__wraper {
    border: 5px solid #070707;
    height: auto;
    padding: 0;
  }
  .warning-msg {
    padding: 0.8rem 0;
  }
  .msg-heading {
    font-weight: 600;
    font-size: 1rem;
    line-height: 120%;
    margin-right: 0.2rem;
    margin-top: 5px;
    text-align: center;
  }
  .msg-heading span svg {
    width: 20px;
    height: 20px;
    margin-top: -5px;
  }

  .msg-text {
    font-weight: 600;
    font-size: 1em;
    padding: 1em;
  }
  .navbar-brand {
    display: block;
    padding-top: 0;
    max-height: 50px;
    z-index: 12;
    position: absolute;
    width: 120px;
    top: 0px;
  }
  .navbar-brand img {
    width: 120px;
    height: auto;
    margin-top: -8px;
  }
  .navbar-toggler {
    position: relative;
    margin-right: 10px;
    border: none;
    padding: 5px;
    display: inline-block;
  }
  .navbar-toggler span {
    background-color: #2daf4a !important;
  }
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 0;
    padding: 15px;
    margin: 0;
  }
  .navbar-mob {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 0;
    margin: 0;
  }
  .user-menu .btn {
    margin: auto;
  }
  .user-pos button {
    color: #2daf4a;
    font-size: 32px;
  }
  .notification-icon-btn {
    /* position: relative; */
    height: 32px;
    width: 32px;
    font-size: 32px;
    cursor: pointer;
    z-index: 10;
    margin: 0 2.5px;
    color: #2daf4a;
  }
  .user-menu .btn:hover {
    color: #2daf4a;
  }
  .notification-icon-btn i,
  .user-menu .btn .user-menu-icon-mobile i {
    font-size: 35px;
  }
  .menu-mobile-icons .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: 9px;
  }
  .user-pos {
    top: 0;
    right: 0;
    position: relative;
  }
  .user-pos .user-menu {
    margin: 0;
    padding: 0;
  }
  .notification-icon-btn a {
    padding: 0 !important;
  }
  .menu-notification-icon .badge {
    top: -7px;
  }
  .dropdown .user-menu-wrap {
    transform: translateX(110%);
    visibility: hidden;
    opacity: 0;
  }
  .dropdown.show .user-menu-wrap {
    position: fixed;
    left: 0;
    width: 100%;
    background: #fff;
    transition: all 0.3s ease;
    padding: 10px;
    z-index: 10;
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    top: 135px;
    height: calc(100% - 135px);
    color: #fff;
  }

  .user-menu-panel {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .user-menu-panel ul {
    padding: 0;
    margin: 0;
  }
  .dropdown-menu-right .nav-item {
    width: 50%;
    align-items: center;
    display: inline-flex;
  }
  .user-menu-panel ul::after {
    right: 65px;
    background: #2daf4a;
    border-top: 1px solid #f2f4f9;
    border-left: 1px solid #f2f4f9;
  }
  .user-menu .dropdown-menu {
    min-width: 100% !important;
    text-align: center;
  }
  .dropdown-menu-right {
    border: none;
  }
  .dropdown-menu-right .nav-item .dr-nav-link {
    padding: 10px 7px;
    font-size: 0.8em;
    background: #141414;
    color: #fff;
    font-weight: 600;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
  }
  .dropdown-menu-right .nav-item .dr-nav-link span {
    color: #fff;
  }
  .user-menu-heading {
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;
    font-family: 'avenirNextMedium';
    margin: 0 auto;
    color: #000;
    text-transform: uppercase;
  }
  .user-account {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .menu-reward-level {
    text-align: center;
    margin: 0 auto;
    display: inherit;
  }
  .user-account .user-pts {
    font-size: 1.5em;
    font-weight: 600;
    margin: 0;
    text-align: center;
    color: #141414;
  }

  .h1,
  h1 {
    font-size: 32px;
  }
  .carousel-caption {
    left: 0%;
    top: 10px;
  }
  .carousel-caption h1 {
    font-size: 1.8em;
    margin-bottom: 24px;
  }
  .carousel-caption .btn-primary {
    font-size: 17px;
    padding: 15px 15px 15px;
  }
  /* pages */

  /* home */
  .home-banner-img {
    background: url(/public/assets/images/leapvapor-banner.jpg) 670px center;
    min-height: 330px;
    background-size: cover;
  }
  .home-faq h1 {
    font-size: 1.5em;
  }
  .home-faq .accordion h5 {
  }
  .home-faq .card-header {
    padding: 8px 0px;
  }

  .card-header .col-11 {
    padding-right: 5px;
    max-width: 89%;
  }

  /* end home */
  .register-page .card {
    padding: 20px 50px;
  }
  .form-group {
    padding-left: 0px;
    padding-right: 0px;
  }
  /* activity details pages */
  .btn-prev,
  .btn-next {
    width: 110px;
    min-width: 100px;
  }
  /* end pages */
  /* Footer Section */
  .footer {
    padding-top: 0;
  }
  .social-links {
    justify-content: center;
  }
  .footer-logo_wrap img {
    width: 130px;
    position: relative;
    top: 0;
    transform: translateY(0);
  }
  .footer-link ul {
    text-align: center;
  }
  .footer-link ul li {
    padding-left: 2px;
    padding-right: 2px;
    line-height: 36px;
    text-align: center;
    float: inherit;
    display: inline-block;
  }
  .copy-text {
    text-align: center;
    margin-top: 0px;
  }
  .copy-text p {
    font-size: 16px;
  }
  .tierlogic {
    line-height: 20px;
    display: inline-block;
    margin-left: 4px;
    margin: 0;
  }
  .current-level,
  .next-level {
    max-width: 31%;
  }
  .current-percentage {
    float: right;
    width: calc(100% - 62%);
  }
  .current-percentage .progress {
    height: 15px;
  }
  /* End Footer Section */
  .modal-dialog {
    margin: 0.5rem 0;
  }

  .modal-green-bg .modal-content .btn-primary {
    width: 120px;
    min-width: 100px;
  }
  .tier-col {
    padding: 0 5px !important;
  }
}

/* @media (max-width: 767px) {
  .navbar-collapse {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 20em;
    height: 100vh;
    box-shadow: 5px 0 0 #000;
  }

  .navbar-collapse.collapsing {
    height: auto;
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -moz-transition: left 0.3s ease;
    transition: left 0.3s ease;
    left: -20.3em;
    height: 100vh;
  }

  .navbar-collapse.show {
    left: 0;
    -webkit-transition: left 0.3s ease-in;
    -o-transition: left 0.3s ease-in;
    -moz-transition: left 0.3s ease-in;
    transition: left 0.3s ease-in;
  }

  .navbar-toggler {
    position: absolute;
    top: 10%;
    bottom: 10%;
    right: 3%;
  }
}

.navbar-toggler {
  top: 27%;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler span {
  display: block;
  background-color: #444;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform 0.35s ease-in-out;
  -moz-transition: transform 0.35s ease-in-out;
  -o-transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
} */
/* .collapse:not(.show){display: inline-block;} */

/* MUI theming */
.MuiFormControl-root {
  background: none !important;
  width: 100%;
  border-radius: 4px 4px 0 0;
  font-family: 'avenirNextRegular' !important;
}
.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 700 !important;
}

.MuiFormControl-root:hover {
  background: none !important;
}
.MuiSelect-select.MuiSelect-select {
  font-family: 'avenirNextRegular' !important;
  text-align: left;
  padding-left: 15px;
}
.MuiInputLabel-asterisk,
.MuiFormLabel-asterisk {
  color: red;
  font-size: 12px;
  margin-top: -15px;
  position: absolute;
}

/* MUI  */
.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 0;
  font-size: 1rem;
  font-family: 'avenirNextRegular' !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}
.MuiInputBase-root,
.MuiFormHelperText-root {
  font-family: 'avenirNextRegular' !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #2daf4a !important;
}
.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}
.MuiFormLabel-colorSecondary.Mui-focused {
  color: #f50057;
}
.MuiFormLabel-asterisk.Mui-error {
  color: #f44336;
}
.MuiFilledInput-root {
  font-family: 'avenirNextRegular' !important;
  font-size: 1em !important;
  font-weight: 600 !important;
  background-color: #f5f5f5 !important;
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  /* background-color: rgba(0, 0, 0, 0.01) !important; */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.MuiFilledInput-root:hover {
  /* background-color: rgba(0, 0, 0, 0.01)!important; */
}

.MuiFilledInput-root.Mui-focused {
  /* background-color: rgba(0, 0, 0, 0.01)!important; */
}

.MuiFilledInput-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
  .MuiFilledInput-root:hover {
    background-color: rgba(0, 0, 0, 0.01) !important;
  }
}

.MuiFilledInput-colorSecondary.MuiFilledInput-underline:after {
  border-bottom-color: #f50057;
}

.MuiFilledInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #2daf4a !important;
  pointer-events: none;
}

.MuiFilledInput-underline.Mui-focused:after {
  transform: scaleX(1);
}

.MuiFilledInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #f44336 !important;
}

.MuiFilledInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.MuiFilledInput-underline:hover:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

.MuiFilledInput-underline.Mui-disabled:before {
  border-bottom-style: dotted;
}

.MuiFilledInput-adornedStart {
  padding-left: 12px;
}

.MuiFilledInput-adornedEnd {
  padding-right: 12px;
}

.MuiFilledInput-multiline {
  padding: 27px 12px 10px;
}

.MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding-top: 23px;
  padding-bottom: 6px;
}

.MuiFilledInput-input {
  padding: 27px 12px 10px;
}

.MuiFilledInput-input:-webkit-autofill {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.MuiFilledInput-inputMarginDense {
  padding-top: 23px;
  padding-bottom: 6px;
}

.MuiFilledInput-inputHiddenLabel {
  padding-top: 18px;
  padding-bottom: 19px;
}

.MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense {
  padding-top: 10px;
  padding-bottom: 11px;
}
.MuiFilledInput-inputMultiline {
  padding: 0;
}
.MuiFilledInput-inputAdornedStart {
  padding-left: 0;
}
.MuiFilledInput-inputAdornedEnd {
  padding-right: 0;
}
/* .MuiFilledInput-input {
  background: #fff !important;
} */
.MuiFormHelperText-root {
  color: red !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2daf4a !important;
}
.MuiOutlinedInput-input:-webkit-autofill,
.MuiInputBase-input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill textarea:-internal-autofill-selected,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
.MuiSelect-select:focus {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #2daf4a !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: rgb(45 175 74 / 4%) !important;
}
.MuiFormControlLabel-root {
  margin-bottom: 6px;
}

.MuiFormControlLabel-label {
  font-family: 'avenirNextRegular' !important;
  font-size: 1em !important;
  font-weight: 600 !important;
}
.questions-card {
  margin-bottom: 120px;
}
.questions-card .card .MuiFormLabel-root {
  color: #000 !important;
}
.questions-card .card .MuiFormControlLabel-root {
  margin: -4px 0px -4px 0px !important;
}
.questions-card .card {
  background-color: #efefef;
  padding: 20px;
  margin: 10px 0px;
  color: #000;
  width: 100%;
  display: block;
  cursor: default;
  box-shadow: none;
}
.questions-card .card:hover {
  box-shadow: none;
}

.mat-raised-button {
  font-family: 'AvenirNextRegular';
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-button-focus-overlay {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1), background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-button-focus-overlay {
  background: #000;
}

.mat-button-focus-overlay {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1), background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -1px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: 6px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-warn .mat-badge-content {
  color: #fff;
  background: #f44336;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  font-weight: 600;
  font-size: 12px;
  font-family: 'AvenirNextRegular';
}

.video-controls {
  position: absolute;
  display: flex;
  width: 95.6%;
  height: 50px;
  z-index: 3;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: bottom 1s;
  -khtml-transition: bottom 1s;
  -moz-transition: bottom 1s;
  -ms-transition: bottom 1s;
  transition: bottom 1s;
}

.video-controls .btn-link,
.video-controls span {
  color: #fff;
}
.overlay-play-button {
  /* Used to position the overlay */
  position: relative;
}

.overlay-play-button__overlay {
  /* Position */
  left: 0;
  position: absolute;
  top: 0;

  /* Take full size */
  height: calc(100% - 6px);
  width: 100%;

  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Add a dark background */
  background-color: rgba(0, 0, 0, 0.1);
}

.overlay-play-button__play {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 30px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.6);

  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.3s all ease;
}
.overlay-play-button__overlay:hover .overlay-play-button__play {
  border-width: 30px 0 30px 40px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.95);
}

.video-controls .progress {
  background-color: #8c7530 !important;
}
.video-controls .bg-success {
  background-color: #fff !important;
}
.MuiSlider-rail {
  height: 4px !important;
  border-radius: 0 !important;
  background-color: #000 !important;
}
.MuiSlider-track {
  height: 4px !important;
  border-radius: 0 !important;
}
.contest-container {
  background: url('/public/activities/white-bg.jpg') repeat-x;
}
.opicity06 {
  opacity: 0.6;
}
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #2caf49 !important;
}

.filter-select .form-control {
  padding: 0 5px 0 10px;
  height: 40px;
  margin-bottom: 20px;
}
.MuiInputBase-adornedEnd {
  background-color: transparent !important;
}
.lockItem {
  background: rgba(0, 0, 0, 0.9);
}
.h6-white {
  background-color: #fff !important;
  color: #000 !important;
  padding: 0 !important;
}
.h6-white a {
  color: #2daf4a !important;
}
.rewardBackImg {
  height: 150px;
  width: 100%;
  background: #786325 url('/public/rewards/smoke-over.jpg') center left;
}
