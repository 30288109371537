.rewards-list-container {
	.header-container {
		padding-top: 50px;
		border-bottom: 2px solid #2d2a2a;
	}
	
	.rewards-category-div {
		float: right;
		width: 170px;
		max-width: 170px;
		display: inline-block;
		label{
			width: 60px;
			float: left;
			text-align: right;
			padding-right: 10px;
		}
		select{
			width: 100px;
			float: right;
			border-bottom: 1px; 
		}
	}
	.label {
		font-weight: 600;
		font-size: 25px;
		margin: 30px 0px;
		border-bottom: 2px solid #2d2a2a;
	}
	.container-card-btn {
		text-align: center;
	}
	
	
	h4 {
		text-align: center;
		font-size: medium;
		padding-top: 5px;
	}
}
.pac-container {
	z-index: 1100 !important;
}
